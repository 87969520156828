<template>
  <v-container fluid class="pt-0">
    <PageHeader title="스토어 검색" subtitle="펀다 상점 검색">
      <template v-slot:left>
        <v-btn
          icon
          @click="$router.push({ name: 'ai/smart-store/list' })"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
    </PageHeader>
    <SearchTable :query="query" :isQueryPaging="true" />
  </v-container>
</template>

<script>
import PageHeader from '@/components/common/page/PageHeader.vue'
import SearchTable from '@/components/ai/smart-store/SearchTable.vue'

export default {
  name: 'SmartStoreSearch',
  components: {
    PageHeader,
    SearchTable,
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10,
      },
    }
  },
}
</script>

<style scoped>
.v-card {
  margin: 16px;
}
</style>
