<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          @click:row="onRowClick"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                label="상점명 검색"
                append-icon="mdi-magnify"
                single-line
                hide-details
                @keyup.enter="onSearch"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.dateJoined="{ item }">
            {{ formatDate(item.dateJoined) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- 스토어 정보 다이얼로그 -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>스토어 정보</v-card-title>
        <v-card-text>
          <div v-if="dialogLoading" class="text-center py-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-else-if="selectedStore">
            <v-simple-table>
              <tbody>
                <tr>
                  <td>스토어 ID</td>
                  <td>{{ selectedStore.id }}</td>
                </tr>
                <tr>
                  <td>스토어명</td>
                  <td>{{ selectedStore.name }}</td>
                </tr>
                <tr>
                  <td>사업자번호</td>
                  <td>{{ selectedStore.businessLicenseNumber }}</td>
                </tr>
                <tr>
                  <td>휴폐업여부</td>
                  <td>{{ selectedStore.isClosed ? 'O' : 'X' }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-4"></v-divider>
            <v-text-field
              v-model="naverplaceId"
              label="네이버 플레이스 ID"
              placeholder="네이버 플레이스 ID를 입력하세요"
              :error-messages="naverplaceIdError"
              @input="naverplaceIdError = ''"
              outlined
              dense
            ></v-text-field>
          </div>
          <div v-else class="text-center red--text">
            스토어 정보를 찾을 수 없습니다.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedStore"
            color="primary"
            @click="addStore"
            :loading="addLoading"
            :disabled="!naverplaceId"
          >
            스토어 추가
          </v-btn>
          <v-btn
            text
            @click="dialog = false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import smartStoreApi from '@/plugins/api/now/smart-store'

export default {
  name: 'SearchTable',
  props: {
    query: {
      type: Object,
      required: true,
    },
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      totalItems: 0,
      headers: [
        { text: 'ID', value: 'id' },
        { text: '이름', value: 'name' },
        { text: '이메일', value: 'email' },
        { text: '전화번호', value: 'phone' },
        { text: '가입일', value: 'dateJoined' },
      ],
      items: [],
      dialog: false,
      dialogLoading: false,
      selectedStore: null,
      addLoading: false,
      naverplaceId: '',
      naverplaceIdError: '',
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleDateString('ko-KR')
    },
    async fetchItems() {
      this.loading = true
      try {
        const { data } = await this.$fundaNowApi.getUsers({
          ...this.query,
          keyword: this.search,
          page: this.options.page,
          size: this.options.itemsPerPage,
        })

        this.items = data.data || []
        this.totalItems = data.paging.totalCount || 0
      } catch (error) {
        console.error('스토어 검색 실패:', error)
        this.items = []
        this.totalItems = 0
      } finally {
        this.loading = false
      }
    },
    onSearch() {
      this.options.page = 1
      this.fetchItems()
    },
    async onRowClick(item) {
      this.dialog = true
      this.dialogLoading = true
      this.selectedStore = null
      this.naverplaceId = ''
      this.naverplaceIdError = ''

      try {
        // 사용자 ID로 스토어 정보 조회
        const { data } = await this.$fundaNowApi.getUserStore(item.id)
        this.selectedStore = data
      } catch (error) {
        console.error('스토어 정보 조회 실패:', error)
      } finally {
        this.dialogLoading = false
      }
    },
    async addStore() {
      if (!this.selectedStore || !this.naverplaceId) return

      this.addLoading = true
      try {
        const { data } = await smartStoreApi.createStore({
          store_id: this.selectedStore.id,
          naverplace_id: this.naverplaceId,
        })

        // 성공 메시지 표시
        // this.$toast.success('스마트스토어가 성공적으로 등록되었습니다.')

        // 응답 데이터 확인을 위한 콘솔 출력
        console.log('스마트스토어 등록 결과:', data)

        this.dialog = false
        this.$router.push({ name: 'ai/smart-store/list' })
      } catch (error) {
        console.error('스토어 추가 실패:', error)
        // 상세 에러 메시지 표시
        this.naverplaceIdError = error.data?.message || '스토어 추가에 실패했습니다. 네이버 플레이스 ID를 확인해주세요.'
      } finally {
        this.addLoading = false
      }
    },
  },
  watch: {
    query: {
      handler: 'fetchItems',
      deep: true,
    },
    options: {
      handler: 'fetchItems',
      deep: true,
    },
  },
  created() {
    this.fetchItems()
  },
}
</script>
